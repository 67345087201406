import axios from 'axios'
import config from '../../config/config'
import { errorWrapper } from '../../utils/api'
import { Terminal } from './type'

const url = `${config.serverBaseUrl}/tweb`

class TerminalsApi {
  static get = (username: string): Promise<Terminal> => errorWrapper(
    async () => {
      const { data } = await axios.get(`${url}/${username}`)
      return data
    }
  )
}

export default TerminalsApi