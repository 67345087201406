import { Layout as AntdLayout, Row, Col } from 'antd';
import React, { FC } from 'react';

const { Content } = AntdLayout

const Layout: FC = ({ children }) => (
  <AntdLayout>
    <Content style={{ backgroundImage: 'linear-gradient(to bottom right, #0072BB, #244A74)' }}>
      <Row style={{ height: '100%' }} align='middle' justify='center'>
        <Col>
          {children}
        </Col>
      </Row>
    </Content>
  </AntdLayout>
)

export default Layout