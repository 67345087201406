import { Button, Form, Input } from 'antd'
import React, { FC, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { getTerminalServer, useGetTerminalServerSelector } from '../features/terminals/slices/get'
import { loadUsername, saveUsername } from './localStorage'
import Page from './Page'

const UsernameForm: FC = () => {
  const dispatch = useDispatch()
  const preloadedUsername: string | null = loadUsername()
  const [username, setUsername] = useState<string | undefined>(preloadedUsername || undefined)
  const [errorMessage, setErrorMessage] = useState<string | undefined>()

  const { loading, success, error, terminal } = useGetTerminalServerSelector()

  useEffect(() => {
    if (loading) {
      setErrorMessage(undefined)
    }
    if (success && username && terminal) {
      saveUsername(username)
      window.location.href = `https://${terminal.server}.sologic.fr/tweb/${terminal.twebUsername}`
    } else if (error) {
      setErrorMessage('Identifiant invalide')
    }
  }, [loading, success, error, terminal, username, dispatch])

  const handleFinish = () => {
    if (username) {
      dispatch(getTerminalServer(username))
    }
  }

  return (
    <Page>
      <Form
        size='large'
        layout='inline'
        onFinish={handleFinish}
        initialValues={{
          username: preloadedUsername
        }}
      >
        <Form.Item
          // label='Identifiant'
          name='username'
          help={errorMessage}
          validateStatus={errorMessage && 'error'}
        >
          <Input
            style={{ borderRadius: '5px' }}
            placeholder='Identifiant'
            value={username}
            onChange={e => setUsername(e.target.value)}
          />
        </Form.Item>
        <Form.Item style={{ margin: 0 }}>
          <Button
            style={{ borderRadius: '5px' }}
            type='primary'
            htmlType='submit'
          >
            Suivant
          </Button>
        </Form.Item>
      </Form>
    </Page>
  )
}

export default UsernameForm