const env: any = process.env

const config = {
  serverBaseUrl: env.REACT_APP_SERVER_URL,
  logs: {
    debug: env.REACT_APP_DEBUG
  },
  layout: {
    centeredLayout: {
      breakpoints: {
        'medium': { xs: 22, sm: 22, md: 20, lg: 18, xl: 16, xxl: 14 },
        'large': { xs: 22, sm: 22, md: 20, lg: 20, xl: 20, xxl: 20 },
      }
    }
  }
}

export default config