import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { error, initialRemoteState, reset, start } from '../../../redux/remoteStateWithSuccess';
import { RootState } from '../../../redux/rootReducer';
import { AppThunk } from '../../../redux/store';
import { ApiError } from '../../../types/api';
import { RemoteStateWithSuccess } from '../../../types/redux';
import TerminalsApi from '../api';
import { Terminal } from '../type';

export type GetTerminalServerState = RemoteStateWithSuccess & {
  terminal: Terminal | null
}

const initialState: GetTerminalServerState = {
  terminal: null,
  ...initialRemoteState
}

const {
  reducer,
  actions,
} = createSlice({
  name: 'getTerminalServer',
  initialState,
  reducers: {
    start, error, reset,
    get: (state: GetTerminalServerState, action: PayloadAction<Terminal>): void => {
      state.terminal = action.payload
      state.success = true
      state.loading = false
      state.error = null
    },
  }
})

export default reducer

export const getTerminalServer = (username: string): AppThunk =>
  async dispatch => {
    try {
      dispatch(actions.start())
      const terminal: Terminal = await TerminalsApi.get(username)
      dispatch(actions.get(terminal))
    } catch (err) {
      dispatch(actions.error(err as ApiError))
    }
    dispatch(actions.reset())
  }

export const useGetTerminalServerSelector = (): GetTerminalServerState =>
  useSelector((state: RootState) => state.terminals.get)