import React, { FC } from 'react'
import UsernameForm from './UsernameForm'
import './App.less'
import Layout from './Layout'

const App: FC = () => {
  return (
    <Layout>
      <UsernameForm />
    </Layout>
  )
}

export default App