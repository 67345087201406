export const loadUsername = (): string | null => {
  try {
    return localStorage.getItem('username')
  } catch (err) {
    return null
  }
}

export const saveUsername = (username: string): void => {
  try {
    localStorage.setItem('username', username)
  } catch (err) {
    // Handle error
  }
}